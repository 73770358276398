import React, { useState, startTransition, Suspense } from "react";
import "./App.css";
import Facebook from "./components/common/Facebook";

const site = process.env.REACT_APP_SITE;
const bandName = process.env.REACT_APP_BAND_NAME;
const fbProfile = process.env.REACT_APP_FB_PROFILE;

const Shows = React.lazy(() => import(`./components/${site}/Shows`));
const Home = React.lazy(() => import(`./components/${site}/Home`));
const SongList = React.lazy(() => import(`./components/${site}/SongList`));
const Contact = React.lazy(() => import(`./components/${site}/Contact`));
const Footer = React.lazy(() => import(`./components/${site}/Footer`));

function App() {
  const [selectedMenu, setSelectedMenu] = useState("home");

  const handleMenuClick = (menu) => {
    startTransition(() => {
      setSelectedMenu(menu);
    });
  };

  const renderContent = () => {
    switch (selectedMenu) {
      case "home":
        return <Home />;
      case "shows":
        return <Shows />;
        case "songList":
          return <SongList />;
        case "contact":
        return <Contact />;
      default:
        return <Home />;
    }
  };

  return (
    <div className="app">
      <div className="projectLinks">
        <a href="http://thehankypanks.com/">The Hanky Panks (band)</a> |
        <a href="http://eyesuphere.live/">Eyes Up Here (duo/trio)</a> | 
        <a href="http://kcoopermusic.com/">Kevin Cooper Music (solo)</a>
      </div>
      <img
        src={`${process.env.PUBLIC_URL}/images/${site}/logo.png`}
        className="logo"
        alt="logo"
      />
      <Facebook facebookPage={`${fbProfile}`} bandName={`${bandName}`} />
      <img
        src={`${process.env.PUBLIC_URL}/images/${site}/band-photo.png`}
        className="trillions-kick"
        alt="Band Photo"
      />
      <div className="menu">
        <span
          className={`menu-item ${selectedMenu === "home" ? "selected" : ""}`}
          onClick={() => handleMenuClick("home")}
        >
          Home
        </span>
        <span
          className={`menu-item ${selectedMenu === "shows" ? "selected" : ""}`}
          onClick={() => handleMenuClick("shows")}
        >
          Shows
        </span>
        <span
          className={`menu-item ${selectedMenu === "songList" ? "selected" : ""}`}
          onClick={() => handleMenuClick("songList")}
        >
          Song List
        </span>
        <span
          className={`menu-item ${
            selectedMenu === "contact" ? "selected" : ""
          }`}
          onClick={() => handleMenuClick("contact")}
        >
          Contact
        </span>
      </div>
      <div className="content">
        <Suspense fallback={<div>Loading...</div>}>{renderContent()}</Suspense>
      </div>
      <Footer />
    </div>
  );
}

export default App;
